//select2
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
	border-color: $input-border-color !important;
}
.select2-dropdown {
	border-color: $input-border-color !important;
}

.select2-search--dropdown {

	&:before {
		color: $primary-500 !important;
	}
}

.select2-results__message {
	color: $primary-500 !important;
}

.select2-container--open .select2-dropdown--above {
	border-color: $input-focus-border-color !important;
}

.select2-container--open .select2-dropdown--below {
	border-color: $input-focus-border-color !important;
}


.select2-container--default .select2-search--dropdown .select2-search__field {
  color: $input-color !important;
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
  box-shadow: inset 0 .25rem .125rem 0 rgba(33, 37, 41, .025);

  &:focus {
  	border-color: darken($input-border-color, 10%);
  }
}

/*.select2-container--default .select2-results__group {
    padding: 0.5rem 0;
    color: #8e8e8e;
}
*/
.select2-container--default .select2-results__option[aria-selected=true] {
	background: $dropdown-link-active-bg !important;
	color: $dropdown-link-active-color !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: $primary-500 !important;
	color: $white !important;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, 
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, 
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
	border-color: $input-focus-border-color !important;
}

.select2-container--default.select2-container--focus .select2-selection--single,
.select2-container--default.select2-container--focus .select2-selection--multiple {
	border-color: $input-focus-border-color !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
	background: $dropdown-link-active-bg !important;
	border-color: $primary-400 !important;
	color: $dropdown-link-active-color !important;

	.select2-selection__choice__remove {
		color: lighten($primary-500, 10%) !important;

		&:hover {
			color: $primary-500 !important;
		}

		&:active {
			box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
		}
	}
}

.select2-container--default .select2-selection--single .select2-selection__clear {
	color: $danger-500 !important;

	&:hover {
		color: $danger-600 !important;
	}
}
.select2-results__message {
	color: $danger-500 !important;
}