.bg-trans-gradient {
	background: -webkit-linear-gradient(250deg, desaturate($info-500, 25%), desaturate($primary-500, 10%));
	background: linear-gradient(250deg, desaturate($info-500, 25%), desaturate($primary-500, 10%));
}

.bg-brand-gradient {
	@extend %nav-bg;
}

.notes {
	background: #f9f4b5;
}

/* disclaimer class */
.disclaimer {
	color: #a2a2a2;
}

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }
}


/* online status */
.status {
	position: relative;

	&:before {
		background: $fusion-500;
		border: 2px solid #fff;
	}

	&.status-success:before {
		background: $success-500;
	}

	&.status-danger:before {
		background: $danger-500;
	}

	&.status-warning:before {
		background: $warning-500;
	}
}


/* display frame */
.frame-heading {
	color: lighten($fusion-50, 7%);
}
.frame-wrap {
	background: white;
}

/* time stamp */
.time-stamp {
	color: $fusion-200;
}

/* data-hasmore */
[data-hasmore] {
	color: $white;
	&:before {
		background: rgba($black, 0.4);
	}
}

/* code */
code {
	background: lighten(desaturate($primary-800, 60%), 56%);
}